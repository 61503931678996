<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        extension-height="10"
        color="grey darken-4"
        dark
      >
        <v-toolbar-title>Training Materials</v-toolbar-title>
      </v-toolbar>
      <!-- <v-subheader>Training Materials</v-subheader> -->
      <v-list-tile>
        <v-icon left>
          mdi-video
        </v-icon>
        <span>DML 101 (Part 1) : PENGENALAN DEALER</span>
        <v-spacer />
        <v-btn
          href="https://onesys.onexox.my/form/DML101_PART1.mp4"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <v-icon left>
          mdi-video
        </v-icon>
        <span>DML 101 (Part 2) : SISTEM ONESYS</span>
        <v-spacer />
        <v-btn
          href="https://onesys.onexox.my/form/DML101_PART2.mp4"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <v-icon left>
          mdi-video
        </v-icon>
        <span>DML 101 (Part 3) : ONEXAPP, SELF CARE PORTAL, BLACK APP, BLACK MARKET DAN ADX</span>
        <v-spacer />
        <v-btn
          href="https://onesys.onexox.my/form/DML101_PART3.mp4"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <v-icon left>
          mdi-video
        </v-icon>
        <span>DML 102 : PRODUK PRABAYAR ONEXOX</span>
        <v-spacer />
        <v-btn
          href="https://onesys.onexox.my/form/DML_102.mp4"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <v-icon left>
          mdi-video
        </v-icon>
        <span>DML 103 : PRODUK PASCABAYAR ONEXOX (BLACK)</span>
        <v-spacer />
        <v-btn
          href="https://onesys.onexox.my/form/DML_103.mp4"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <v-icon left>
          mdi-video
        </v-icon>
        <span>DML 104 : DEALER AFFILIATE PROGRAM</span>
        <v-spacer />
        <v-btn
          href="https://onesys.onexox.my/form/DML_104.mp4"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
    </v-card>
  </panel>
</template>

<script>

const pageTitle = 'Training Materials'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'XOX LEAD Program', disabled: false, to: '/lead_menu',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      title: pageTitle,
      items: {},
      item: {},
      loading: false,
    }
  },
}
</script>
